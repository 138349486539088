import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RestaurantService } from './restaurant.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private restaurantService: RestaurantService,
    private router: Router
  ) { }

  public initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    }
  }

  public addToken(newToken: string): void {
    console.log('new Token: ', newToken);
    this.authService.token.subscribe((authToken) => {
      if (authToken) {
        this.authService.userId.subscribe((userId) => {
          if (userId) {
            // Fetch the associated restaurant ID
            this.restaurantService.getAssociatedRestaurantId(userId).subscribe((restaurantId) => {
              if (restaurantId) {
                const endpoint = `/restaurants/${restaurantId}/device/.json`;

                // Fetch the existing tokens
                this.http
                  .get<{ [key: string]: string }>(`${environment.firebase.databaseURL}${endpoint}?auth=${authToken}`)
                  .pipe(
                    map((tokenData) => {
                      const existingTokens = tokenData ? Object.values(tokenData) : [];
                      // Check if the newToken is already in the list, if not, add it
                      if (!existingTokens.includes(newToken)) {
                        existingTokens.push(newToken);
                      }
                      return existingTokens;
                    })
                  )
                  .subscribe((updatedTokens) => {
                    // Update the token list in the Realtime Database
                    this.http
                      .put(`${environment.firebase.databaseURL}${endpoint}?auth=${authToken}`, updatedTokens)
                      .subscribe();
                  });
              }
            });
          }
        });
      }
    });
  }

  private async registerPush() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting

    // If it's an iOS device, request permissions
    if (Capacitor.getPlatform() === 'ios') {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        }
      });
    }

    if (Capacitor.getPlatform() === 'android') {
      let permStatus = await PushNotifications.checkPermissions();
      console.log('checkPermission: ', JSON.stringify(permStatus));
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
        console.log('requestPermission: ', JSON.stringify(permStatus));
        if (permStatus.receive === 'granted') {
          PushNotifications.register();
          console.log('registered');
        }
      } else if (permStatus.receive === 'granted') {
        PushNotifications.register();
        console.log('registered');
      }
    }

    PushNotifications.addListener('registration', (token: Token) => {
      this.addToken(token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Error on registration: ', error);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ', JSON.stringify(notification));
        this.router.navigateByUrl('/reservations/list');
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ', JSON.stringify(notification));
        this.router.navigateByUrl('/reservations/list');
      },
    );
  }
}
