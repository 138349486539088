export class Restaurant {
  constructor(
    public name: string,
    public smsName: string,
    public address: Address,
    public phone: string,
    public email: string,
    public type: string,
    public panier: number,
    public isVegetarian: boolean,
    public isVegan: boolean,
    public hasExterior: boolean,
    public availability?: Availability,
    public integration?: Integration,
    public shortURL?: string,
  ) { }
}

export class Integration {
  constructor(
    public isAsync: boolean,
    public asyncFrom: number,
    public hasSms: boolean,
    public smsFrom: number,
  ) { }
}

export class Address {
  constructor(
    public streetAddress: string,
    public locality: string,
    public postalCode: string,
    public country: string,
    public geo?: Geo,
  ) { }
}

export class Geo {
  constructor(
    public latitude: number,
    public longitude: number,
  ) { }
}

export class Table {
  constructor(
    public quantity: number,
    public maxQuantity: number,
    public maxGuest: number,
    public isJoinable: boolean,
  ) {}
}

export class Availability {
  constructor(
    public monday?: DaysService,
    public tuesday?: DaysService,
    public wednesday?: DaysService,
    public thursday?: DaysService,
    public friday?: DaysService,
    public saturday?: DaysService,
    public sunday?: DaysService,
    public settings?: AvailabilitySettings,
    public exceptionalClosures?: SpecificTimes[],
    public exceptionalOpenings?: SpecificTimes[]
  ) {}
}

export class SpecificTimes {
  constructor(
    public date: string, // YYYY-MM-DD format to specify the exact date
    public services?: string[], // ['lunch', 'dinner'] to close specific services
    public lunchHours?: string[], // ['12:15', '14:00'] to close specific hours
    public dinnerHours?: string[] // ['19:15', '22:00'] to close specific hours
  ) { }
}


export class AvailabilitySettings {
  constructor(
    public hasSameHour: boolean,
    public defaultTable: boolean,
    public sameCapacity: boolean,
    public global: DaysService,
    public closingDays: number[]
  ) {}
}

export class DaysService {
  constructor(
    public lunch: AvailabilitySlot,
    public dinner: AvailabilitySlot
  ) {}
}

export class AvailabilitySlot {
  constructor(
    public start: string,
    public end: string,
    public maxGuest?: number,
    public tables?: Table[] | null
  ) { }
}

export class Device {
  constructor(
    public token: string,
    public timestamp: number
  ) {}
}
