import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Address, Availability, Geo, Integration, Restaurant } from '../models/restaurant.model';
import { Database, ref, set, update, objectVal, push } from '@angular/fire/database';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  private dbPath = '/restaurants';
  private userRestaurantsPath = '/userRestaurants';

  constructor(
    private auth: AuthService,
    private db: Database
  ) { }

  get restaurantId$(): Observable<string | null> {
    return this.auth.userId.pipe(
      switchMap(userId => {
        if (!userId) {
          throw new Error('User is not authenticated');
        }
        //return objectVal<Restaurant>(ref(this.db, `${this.dbPath}/${firstRestaurantId}`));
        return objectVal<{ [key: string]: boolean }>(ref(this.db, `${this.userRestaurantsPath}/${userId}`))
          .pipe(
            map(userRestaurants => {
              // Assuming each user is associated with one restaurant, get the first restaurantId
              const restaurantIds = Object.keys(userRestaurants || {});
              return restaurantIds.length > 0 ? restaurantIds[0] : null;
            })
          );
      })
    );
  }

  get restaurantShortURL$(): Observable<string | null> {
    return this.restaurantId$.pipe(
      switchMap(restaurantId => {
        if (!restaurantId) {
          return of(null);
        }
        return objectVal<string>(ref(this.db, `${this.dbPath}/${restaurantId}/shortURL`));
      })
    );
  }

  createRestaurant(
    name: string,
    streetAddress: string,
    locality: string,
    postalCode: string,
    phone: string,
    email: string,
    type: string,
    panier: number,
    isVegetarian: boolean,
    isVegan: boolean,
    hasExterior: boolean,
    longitude: number,
    latitude: number
  ): Promise<Restaurant> {
    return new Promise((resolve, reject) => {
      this.auth.userId.pipe(
        take(1),
        switchMap(userId => {
          if (!userId) {
            reject(new Error('User is not authenticated'));
            return;
          }

          // Reference to the 'restaurants' node
          const restaurantsRef = ref(this.db, 'restaurants');

          // Generate a new restaurant ID
          const newRestaurantRef = push(restaurantsRef);


          const address = new Address(streetAddress, locality, postalCode, 'FR', new Geo(longitude, latitude));
          const defaultIntegration = new Integration(true, 1, true, 5);
          const restaurant = new Restaurant(
            name,
            name,
            address,
            phone,
            email,
            type,
            panier,
            isVegetarian,
            isVegan,
            hasExterior,
            null,
            defaultIntegration,
            null
          );

          // Store restaurant data
          return set(newRestaurantRef, restaurant)
            .then(() => {
              // Store user-restaurant association
              const userRestaurantAssocRef = ref(this.db, `${this.userRestaurantsPath}/${userId}/${newRestaurantRef.key}`);
              return set(userRestaurantAssocRef, true);
            })
            .then(() => resolve(restaurant));
        })
      ).subscribe();
    });
  }

  updateRestaurant(
    name: string,
    streetAddress: string,
    locality: string,
    postalCode: string,
    phone: string,
    email: string,
    type: string,
    panier: number,
    isVegetarian: boolean,
    isVegan: boolean,
    hasExterior: boolean
  ): Promise<Restaurant> {
    return new Promise((resolve, reject) => {
      this.auth.userId.pipe(
        take(1),
        switchMap(userId => {
          if (!userId) {
            reject(new Error('User is not authenticated'));
            return;
          }

          // Get the reference to the user's restaurants
          const userRestaurantsRef = ref(this.db, `${this.userRestaurantsPath}/${userId}`);

          return objectVal<{ [key: string]: boolean }>(userRestaurantsRef).pipe(
            take(1),
            switchMap(userRestaurants => {
              if (userRestaurants) {
                // Get the first restaurant ID
                const restaurantId = Object.keys(userRestaurants)[0];

                // Fetch current restaurant data
                return objectVal<Restaurant>(ref(this.db, `${this.dbPath}/${restaurantId}`)).pipe(
                  take(1),
                  switchMap(restaurant => {
                    // Update restaurant data
                    this.updateProperty(restaurant, 'name', name);
                    this.updateProperty(restaurant.address, 'streetAddress', streetAddress);
                    this.updateProperty(restaurant.address, 'locality', locality);
                    this.updateProperty(restaurant.address, 'postalCode', postalCode);
                    this.updateProperty(restaurant, 'phone', phone);
                    this.updateProperty(restaurant, 'email', email);
                    this.updateProperty(restaurant, 'type', type);
                    this.updateProperty(restaurant, 'panier', panier);
                    this.updateProperty(restaurant, 'isVegetarian', isVegetarian);
                    this.updateProperty(restaurant, 'isVegan', isVegan);
                    this.updateProperty(restaurant, 'hasExterior', hasExterior);

                    // Save updated restaurant data
                    return update(ref(this.db, `${this.dbPath}/${restaurantId}`), restaurant)
                      .then(() => resolve(restaurant));
                  })
                );
              } else {
                reject(new Error('No restaurants found for the user'));
                return;
              }
            })
          );
        })
      ).subscribe();
    });
  }

  // Update property for the restaurant update
  updateProperty(object, property, newValue) {
    if (newValue !== null) {
      object[property] = newValue;
    }
  }

  getRestaurant(): Observable<Restaurant> {
    return this.auth.userId.pipe(
      take(1),
      switchMap(userId => {
        if (!userId) {
          throw new Error('User is not authenticated');
        }

        // Get the reference to the user's restaurants
        const userRestaurantsRef = ref(this.db, `${this.userRestaurantsPath}/${userId}`);

        // Get the list of restaurant IDs associated with the user
        return objectVal<{ [key: string]: boolean }>(userRestaurantsRef).pipe(
          switchMap(userRestaurants => {
            if (userRestaurants) {
              // Assuming there is at least one restaurant, get the first one
              const firstRestaurantId = Object.keys(userRestaurants)[0];
              return objectVal<Restaurant>(ref(this.db, `${this.dbPath}/${firstRestaurantId}`));
            }
            return of(null);
          })
        );
      })
    );
  }

  addIntegration(integration: Integration): Promise<Integration> {
    return new Promise((resolve, reject) => {
      this.auth.userId.pipe(
        take(1),
        switchMap(userId => {
          if (!userId) {
            reject(new Error('User is not authenticated'));
            return;
          }

          return this.getAssociatedRestaurantId(userId).pipe(
            switchMap(restaurantId => {
              if (restaurantId) {
                return set(ref(this.db, `restaurants/${restaurantId}/integration`), integration)
                  .then(() => resolve(integration));
              } else {
                reject(new Error('No associated restaurant found'));
                return;
              }
            })
          );
        })
      ).subscribe();
    });
  }

  getIntegration(): Observable<Integration> {
    return this.auth.userId.pipe(
      take(1),
      switchMap(userId => {
        if (!userId) {
          throw new Error('User is not authenticated');
        }

        return this.getAssociatedRestaurantId(userId).pipe(
          switchMap(restaurantId => {
            if (restaurantId) {
              return objectVal<Integration>(ref(this.db, `restaurants/${restaurantId}/integration`));
            }
            return of(null);
          })
        );
      })
    );
  }

  getAssociatedRestaurantId(userId: string): Observable<string | null> {
    return objectVal<{ [key: string]: boolean }>(ref(this.db, `userRestaurants/${userId}`)).pipe(
      map(userRestaurants => userRestaurants ? Object.keys(userRestaurants)[0] : null)
    );
  }

}
