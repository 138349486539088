// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LOG_LEVEL } from '@revenuecat/purchases-capacitor';

export const environment = {
  firebase: {
    apiKey: 'AIzaSyAbCD1m5yaqt0gtNITkbAYP0ydWQMXeqlI',
    authDomain: 'resapp-test-a043e.firebaseapp.com',
    databaseURL: 'https://resapp-test-a043e-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'resapp-test-a043e',
    storageBucket: 'resapp-test-a043e.appspot.com',
    messagingSenderId: '1045830939331',
    appId: '1:1045830939331:web:2ba418b43c93d084a26876',
    measurementId: 'G-DN9Q8V1NEG'
  },
  production: false,
  isSandbox: true,
  appVersion: '2.6.9',
  logLevel: LOG_LEVEL.DEBUG,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
