import { Injectable } from '@angular/core';
import {
  Auth,
  idToken,
  User,
  user,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail
} from '@angular/fire/auth';
import { AlertController } from '@ionic/angular';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { Observable, catchError, from, map, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User | null>;
  idToken$: Observable<string | null>;

  constructor(
    private alertCtrl: AlertController,
    private auth: Auth,
  ) {
    this.user$ = user(this.auth);
    this.idToken$ = idToken(this.auth);
  }

  get isLogin(): Observable<boolean> {
    return this.user$.pipe(
      take(1),
      map(currentUser => {
        if (currentUser) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  // Getter for transition before using angular fire RTDB
  get userId(): Observable<string | null> {
    return this.user$.pipe(
      take(1),
      map(currentUser => {
        if (currentUser) {
          return currentUser.uid;
        } else {
          return null;
        }
      })
    );
  }

  get email(): Observable<string | null> {
    return this.user$.pipe(
      take(1),
      map(currentUser => {
        if (currentUser) {
          return currentUser.email;
        } else {
          return null;
        }
      })
    );
  }

  get token(): Observable<string | null> {
    return this.idToken$;
  }

  get isEmailVerified(): Observable<boolean> {
    console.log('ask for email verified');
    return this.user$.pipe(
      take(1),
      switchMap((currentUser) => {
        if (currentUser) {
          return from(currentUser.reload()).pipe(
            map(() => currentUser.emailVerified),
            catchError(() => of(false))
          );
        } else {
          return of(false);
        }
      })
    );
  }

  async login(email: string, password: string) {
    try {
      const currentUser = await signInWithEmailAndPassword(this.auth, email, password);
      console.log('User created successfully');
      Purchases.logIn({ appUserID: currentUser.user.uid });
      console.log('Purchases initialized');
      return currentUser;
    } catch (error) {
      console.log('Error login user', error);
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        this.showAlert('Email ou mot de passe incorrect', 'Veuillez réessayer');
      } else {
        this.showAlert('Erreur', 'Une erreur est survenue, veuillez réessayer plus tard');
      }
      return null;
    }
  }

  async logout(): Promise<void> {
    try {
      await signOut(this.auth);
    } catch (error) {
      console.error(error);
    }
  }

  async signup(email: string, password: string) {
    try {
      const newUser = await createUserWithEmailAndPassword(this.auth, email, password);
      console.log('User created successfully');
      Purchases.logIn({ appUserID: newUser.user.uid });
      console.log('Purchases initialized');
      await this.sendEmailVerification();
      return newUser;
    } catch (error) {
      console.log('Error creating user', error);
      if (error.code === 'auth/email-already-in-use') {
        this.showAlert('Email déjà utilisé', 'Veuillez utiliser un autre email');
      } else if (error.code === 'auth/invalid-email') {
        this.showAlert('Email invalide', 'Veuillez utiliser un autre email');
      } else if (error.code === 'auth/weak-password') {
        this.showAlert('Mot de passe trop faible', 'Veuillez utiliser un autre mot de passe');
      } else {
        this.showAlert('Erreur', 'Une erreur est survenue, veuillez réessayer plus tard');
      }
      return null;
    }
  }

  async sendEmailVerification(): Promise<boolean> {
    try {
      const currentUser = await this.auth.currentUser;
      await sendEmailVerification(currentUser);
      console.log('Email verification sent');
      return true;
    } catch (error) {
      console.log('Error sending email verification', error);
      return false;
    }
  }

  async sendPasswordResetEmail(email: string): Promise<boolean> {
    try {
      await sendPasswordResetEmail(this.auth, email);
      console.log('Password reset email sent');
      return true;
    } catch (error) {
      console.log('Error sending password reset email', error);
      return false;
    }
  }

  async deleteUser(): Promise<boolean> {
    const currentUser = await this.auth.currentUser;
    try {
      await currentUser.delete();
      console.log('User deleted');
      return true;
    } catch (error) {
      console.log('Error deleting user', error);
      return false;
    }
  }

  async showAlert(header, message) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['Ok']
    });
    await alert.present();
  }
}
