import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { objectVal, ref, Database } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(
    private db: Database,
    private alertController: AlertController,
    private platform: Platform
  ) { }

  checkAppVersion() {
    const isSandbox = environment.isSandbox ? 'sandbox' : 'production';
    const platform = this.platform.is('android') ? 'android' : 'ios';

    // Using objectVal to get an Observable
    objectVal(ref(this.db, `version/${platform}/${isSandbox}`)).pipe(
      take(1),
      map((versionData: any) => {
        console.log('versionData', versionData);
        const [remoteMajor, remoteMinor, remotePatch] = versionData.split('.').map(Number);
        const [localMajor, localMinor, localPatch] = environment.appVersion.split('.').map(Number);

        console.log(remoteMajor > localMajor ||
          (remoteMajor === localMajor && remoteMinor >= localMinor) ||
          (remoteMajor === localMajor && remoteMinor === localMinor && remotePatch > localPatch));

        return (
          remoteMajor > localMajor ||
          (remoteMajor === localMajor && remoteMinor > localMinor) ||
          (remoteMajor === localMajor && remoteMinor === localMinor && remotePatch > localPatch)
        );
      })
    ).subscribe(shouldUpdate => {
      if (shouldUpdate) {
        this.showUpdateAlert();
      }
    });
  }

  async showUpdateAlert() {
    const alert = await this.alertController.create({
      header: 'Mise à jour disponible',
      // eslint-disable-next-line max-len
      message: 'Une nouvelle version de l\'application est disponible. Vous devez la télécharger pour continuer à utiliser l\'application.',
      buttons: [
        {
          text: 'Télécharger',
          handler: () => {
            Browser.open({ url: 'https://resapp.me/app' });
          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
  }
}
