import { Component, OnInit } from '@angular/core';
import { App } from '@capacitor/app';
import { PushNotifications } from '@capacitor/push-notifications';
import { Platform, isPlatform } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { VersionService } from './services/version.service';
import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor';
import { environment } from 'src/environments/environment';
import { FcmService } from './services/fcm.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private versionService: VersionService,
    private platform: Platform,
    private fcmService: FcmService
  ) {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }

  async ngOnInit(): Promise<void> {
    this.authService.isLogin.subscribe((isLogin) => {
      if (isLogin) {
        this.fcmService.initPush();
      }
    });
    this.versionService.checkAppVersion();
    App.addListener('appStateChange', async (state) => {
      if (state.isActive) {
        if (Capacitor.getPlatform() !== 'web') {
          // Clear the app badge when the app is opened
          PushNotifications.removeAllDeliveredNotifications();
          // Update token
          this.authService.isLogin.subscribe((isLogin) => {
            if (isLogin) {
              this.fcmService.initPush();
            }
          });
          // Check version
          this.versionService.checkAppVersion();
        }
      }
    });

    // Setting up revenue cat
    await Purchases.setLogLevel({ level: environment.logLevel });
    if (Capacitor.getPlatform() === 'ios') {
      await Purchases.configure({ apiKey: 'appl_sJIoGUTzHwnLXnwbPlYghjHzzqF' });
    } else if (Capacitor.getPlatform() === 'android') {
      await Purchases.configure({ apiKey: 'goog_AOALJJcnFTRQJzfWeCYGsYxCZBS' });
    }


  }

  onLogout() {
    this.authService.logout();
  }
}
